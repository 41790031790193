<template>
	<div id="moneyRecord" class="el-content">
		<a-button class="mb12" @click="$router.go(-1)">返回</a-button>
		<a-table :pagination="false" v-if="search.type ==1" row-key="id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'类型',dataIndex:'re_type_name'},
			{title:'交易时间',dataIndex:'create_time'},
			{title:'名称',dataIndex:'description'},
			{title:'收支类型',dataIndex:'type_name'},
			{title:'金额(元)',dataIndex:'amount',slots:{customRender:'amount'}},
			{title:'状态',dataIndex:'trade_status_name'},
		]" >
			<template #amount="{record}">
				<span v-if="record.type == 1" style="color: #44aa00;">+ {{record.amount}}</span>
				<span v-if="record.type == 2" style="color: #e50000;">- {{record.amount}}</span>
			</template>
			
			<template #expandedRowRender="{record}">
			    <a-table row-key="id" :data-source="record.log" :pagination="false" :columns="[
					{title:'ID',dataIndex:'id'},
					{title:'类型',dataIndex:'operation_type_show',slots:{customRender:'operation_type_show'}},
					{title:'交易时间',dataIndex:'create_time'},
					{title:'名称',dataIndex:'description'},
					{title:'金额(元)',dataIndex:'change',slots:{customRender:'change'}},
				]">
					<template #operation_type_show="{record}">
						<span v-if="record.type == 1">增加可用</span>
						<span v-if="record.type == 2">减少可用</span>
						<span v-if="record.type == 3">增加冻结</span>
						<span v-if="record.type == 4">减少冻结</span>
					</template>
					<template #change="{record}">
						<span v-if="record.type == 1 || record.type == 3">+</span>
						<span v-else>-</span>
						<span>{{record.change}}</span>
					</template>
			    </a-table>
			</template>
		</a-table>
		<a-table :pagination="false" v-if="search.type ==2" row-key="id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'类型',dataIndex:'operation_type_show',slots:{customRender:'operation_type_show'}},
			{title:'下单时间',dataIndex:'create_time'},
			{title:'名称',dataIndex:'description'},
			{title:'业务单号/支付流水号',dataIndex:'trade.order_number'},
			{title:'收支类型',dataIndex:'trade_status_name',slots:{customRender:'trade_status_name'}},
			{title:'金额(元)',dataIndex:'change',slots:{customRender:'change'}},
			{title:'状态',dataIndex:'trade.trade_status_name'},
		]" >
			<template #operation_type_show="{record}">
				<span v-if="record.type == 1">增加可用</span>
				<span v-if="record.type == 2">减少可用</span>
				<span v-if="record.type == 3">增加冻结</span>
				<span v-if="record.type == 4">减少冻结</span>
			</template>
			<template #trade_status_name="{record}">
				<span v-if="record.type == 1">增加可用</span>
				<span v-if="record.type == 2">减少可用</span>
				<span v-if="record.type == 3">增加冻结</span>
				<span v-if="record.type == 4">减少冻结</span>
			</template>
			<template #change="{record}">
				<span v-if="record.type == 1 || record.type == 3">+</span>
				<span v-else>-</span>
				<span>{{record.change}}</span>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getMoneyRecord(info.page,e)}"
				@change="(e)=>{getMoneyRecord(e,info.limit)}"
			/>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import userModel from '@/api/user.js'
import { useRoute } from 'vue-router'
export default{
	setup(){
		const _d = reactive({
			search:{user_id:0,type:''},
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			}
		})
		const opt = useRoute().query
		_d.search.user_id = opt.id
		_d.search.type = opt.type

		getMoneyRecord(1,_d.info.limit)
		function getMoneyRecord(page,limit){
			userModel.getMoneyTrade(page,limit,_d.search,res=>_d.info = {limit,...res})
		}
		return{
			...toRefs(_d),
			getMoneyRecord
		}
	},
}
</script>

<style>
</style>
